import React, { useState } from "react";
import Button from "../../Tools/Button";
import InputEmoji from "react-input-emoji";
import FormTextArea from "../../Tools/Textarea";
import FormInput from "../../Tools/FormInput";
import { Input } from "reactstrap";

//form imports
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  removeFromStorage,
  sendEmail,
  sendPushNotification,
  sendSMS,
  uploadToStorage,
} from "../../../services/api";
import TextareaWithAttatch from "./TextareaWithAttatch";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react/dist/iconify.js";

const NotificationMessage = ({
  heading,
  allFilter,
  setAllFilter,
  getName,
  selectUnselectUser,
}) => {
  const pageContent = {
    app: {
      hasTitle: true,
      hasAttatch: false,
      titlePlaceholder: "Write your notification title",
      bodyPlaceholder: "Write your notification content...",
    },
    phone: {
      hasTitle: false,
      hasAttatch: false,
      titlePlaceholder: "Write your SMS title",
      bodyPlaceholder: "Write your SMS content...",
    },
    email: {
      hasTitle: true,
      hasAttatch: true,
      titlePlaceholder: "Write your e-mail subject",
      bodyPlaceholder: "Write your e-mail content...",
    },
  };

  const [captchaCode, setCaptchaCode] = useState("");
  const schema = Yup.object().shape({
    title: pageContent?.[allFilter?.sendOn]?.hasTitle
      ? Yup.string().required("This field is required")
      : Yup.string(),
    body: Yup.string().required("This field is required"),
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const [selectedFile, setSelectedFile] = React.useState([]);

  const onSubmit = (data) => {
    let formData = {};
    const type = allFilter.users.length > 0 ? "0" : allFilter.sendTo;
    switch (allFilter.sendOn) {
      case "app":
        formData = {
          userIds: allFilter?.users,
          type: type,
          title: data?.title,
          body: data?.body,
          // token: captchaCode
        };
        sendPushNotification(formData).then((res) => {
          if (res?.status) {
            swal("Success", res?.message, "success");
          } else {
            swal("Error", res?.message, "error");
          }
        });
        break;
      case "phone":
        formData = {
          message: data?.body,
          userIds: allFilter.users,
          type: type,
          token: captchaCode,
        };
        sendSMS(formData).then((res) => {
          if (res?.status) {
            swal("Success", res?.message, "success");
          } else {
            swal("Error", res?.message, "error");
          }
        });
        break;
      case "email":
        formData = {
          subject: data?.title,
          message: data?.body,
          userIds: allFilter?.users,
          type: type,
          attachments: selectedFile?.map((v) => v.s3URL),
        };
        sendEmail(formData).then((res) => {
          if (res?.status) {
            swal("Success", res?.message, "success");
          } else {
            swal("Error", res?.message, "error");
          }
        });
        break;
    }
  };

  // const handleCaptureCaptcha = (code) => {
  //   console.log(code);
  //   setCaptchaCode(code);
  // };

  return (
    <div className="setin">
      <h3>{heading}</h3>
      {pageContent?.[allFilter.sendOn]?.hasTitle && (
        <>
          <FormInput
            intype={"text"}
            event={register}
            name={"title"}
            style={"form-control"}
            placeholder={pageContent?.[allFilter.sendOn]?.titlePlaceholder}
          />
          {errors.title && <p className="helperText">{errors.title.message}</p>}
          <br />
        </>
      )}

      <TextareaWithAttatch
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        control={control}
        errors={errors}
        textareaPlaceholder={pageContent?.[allFilter.sendOn]?.bodyPlaceholder}
        showAttatch={pageContent?.[allFilter.sendOn]?.hasAttatch}
        textareaHeight={"400px"}
        attatchStyles={{ top: "356px", left: "12px", position: "absolute" }}
      />

      {/* <div className="w-100 mt-4 d-flex justify-content-end">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_WEBSITE_KEY}
          onChange={handleCaptureCaptcha}
        />
      </div> */}
      {allFilter.users?.length > 0 && (
        <div className="mt-3">
          <div
            role="button"
            className="d-flex justify-content-between aling-items-center px-1"
            onClick={(e) =>
              setAllFilter({ ...allFilter, users: [], usersData: [] })
            }
          >
            <span className="fw-semibold">Selected Accounts</span>
            <span
              className="text-danger"
              style={{
                fontSize: "12px",
                fontWeight: 700,
              }}
            >
              Remove all
            </span>
          </div>
          <div
            className="d-flex flex-wrap gap-2 border p-3"
            style={{ borderRadius: "8px" }}
          >
            {allFilter.usersData.map((user, i) => {
              return (
                <h5 className="d-flex gap-2 justify-content-between align-items-center border px-2 py-1 rounded-pill mb-0">
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>
                    {getName(user)}
                  </span>
                  <Icon
                    role="button"
                    className="rounded-circle"
                    icon="charm:cross"
                    fontSize={13}
                    onClick={(e) => selectUnselectUser(false, user?._id)}
                    style={{ color: "white", backgroundColor: "#4c7ee8" }}
                  />
                </h5>
              );
            })}
          </div>
        </div>
      )}

      <form className="intype">
        {" "}
        {/* Use className instead of class */}
        <Button
          // disabled={!captchaCode}
          type={"button"}
          title={<img src="assets/images/send.png" alt="img" />}
          onClick={handleSubmit(onSubmit)}
        />
      </form>
    </div>
  );
};

export default NotificationMessage;
