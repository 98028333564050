import { useEffect, useState } from "react";
import NotificationFilter from "./filter";
import NotificationMessage from "./notificationMessage";

const SendNotification = () => {
  const [allFilter, setAllFilter] = useState({
    sendOn: "app",
    sendTo: "1",
    users: [],
    usersData: [],
  });
  useEffect(() => {
    console.log(allFilter);
  }, [allFilter]);
  const getName = (item) => {
    if (item?.fullName && item?.fullName !== "N/A") return item?.fullName;
    else if (item?.dealerShipName && item?.dealerShipName !== "N/A")
      return item?.dealerShipName;
    else if (item?.email && item?.email !== "N/A") return item?.email;
    else if (item?.phoneNumber && item?.phoneNumber !== "N/A")
      return item?.phoneNumber;
    else return "--";
  };
  const selectUnselectUser = (type, id, data) => {
    if (type) {
      setAllFilter({
        ...allFilter,
        users: [...allFilter.users, id],
        usersData: [...allFilter.usersData, data],
      });
    } else {
      setAllFilter({
        ...allFilter,
        users: allFilter.users.filter((v) => v !== id),
        usersData: allFilter.usersData.filter((v) => v?._id !== id),
      });
    }
  };
  return (
    <div class="overview Notifications mb-0">
      <div className="fs-3 mb-4 fw-bold">{"Send notifications"}</div>
      <div class="send_noti">
        <NotificationFilter
          allFilter={allFilter}
          setAllFilter={setAllFilter}
          getName={getName}
          selectUnselectUser={selectUnselectUser}
        />
        <NotificationMessage
          heading={"Make your notification"}
          allFilter={allFilter}
          setAllFilter={setAllFilter}
          getName={getName}
          selectUnselectUser={selectUnselectUser}
        />
      </div>
    </div>
  );
};
export default SendNotification;
