import React, { useEffect, useRef, useState } from "react";
import BasicTable from "../../Tools/Table";
import { featuresTableColoumns } from "../../../data/data";
import BoostrapModel from "../../Tools/BoostrapModel";
import { Icon } from "@iconify/react/dist/iconify.js";
import { apiManager } from "../../../data/apiHandler";
import { ITEM_PER_PAGE } from "../../../data/varible";
import PreferencesModal from "./preferencesModal";
import swal from "sweetalert";
import { Input } from "reactstrap";
import { useDidMountEffectWithDebounce } from "../../../data/hooks";
import { toCamelCase } from "../../../data/utils";
const Features = () => {
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const ref = useRef(currentPage);
  const limit = ITEM_PER_PAGE;
  const [totalPages, setTotalPages] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  //modal state
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const openModal = (data) => {
    setModalData(data);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    setTimeout(() => {
      setModalData(null);
    }, 400);
  };
  const getData = (val) => {
    const searchVal = val || "";
    setLoading(true);
    apiManager(
      "GET",
      `opp/getAllFeatures?lang=en&page=${currentPage}&limit=${limit}&name=${searchVal}`
    )
      .then((res) => {
        if (res?.status) {
          const dataT = res?.data?.features;
          setData(
            dataT.map((v) => ({
              name_en: v?.name?.en,
              name_ar: v?.name?.ar,
              name_ku: v?.name?.ku,
              _id: v?._id,
              rawData: v,
            }))
          );
          setTotalPages(res?.data?.totalPages);
        } else {
          setData([]);
          console.log(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  // Function to search products
  useDidMountEffectWithDebounce(() => {
    getData(searchValue);
  }, [searchValue], 1000);

  const actions = [
    {
      name: "edit",
      label: (
        <Icon
          icon="bxs:message-square-edit"
          fontSize={24}
          style={{ color: "#437ee8" }}
        />
      ),
      onclick: (id, rawData) => {
        console.log("id", id, rawData);
        openModal({ type: "edit", title: "Edit Feature", item: rawData });
      },
      type: "text",
    },
    {
      name: "delete",
      label: (
        <Icon
          icon="ri:delete-bin-4-fill"
          fontSize={25}
          className="mx-2"
          style={{ color: "#ff6666" }}
        />
      ),
      style: "Remove",
      onclick: (id, rawData) => {
        console.log("id", id);
        openModal({ type: "delete", title: "Delete Feature", item: rawData });
      },
      type: "text",
    },
  ];
  const handleSubmitFeatures = (data) => {
    setModalLoading(true);
    let apiData = null;
    switch (modalData?.type) {
      case "add":
        apiData = {
          ...apiData,
          mode: "POST",
          url: "opp/createFeature?lang=en",
          data: {
            name: {
              en: data?.name_en,
              ar: data?.name_ar,
              ku: data?.name_ku,
            },
            value: toCamelCase(data?.name_en)
          },
        };
        break;
      case "edit":
        apiData = {
          ...apiData,
          mode: "PUT",
          url: `opp/updateFeatures?lang=en&id=${modalData?.item?._id}`,
          data: {
            name: {
              en: data?.name_en,
              ar: data?.name_ar,
              ku: data?.name_ku,
            },
          },
        };
        break;
      case "delete":
        apiData = {
          ...apiData,
          mode: "DELETE",
          url: `opp/deleteFeature?lang=en&id=${modalData?.item?._id}`,
          data: {},
        };
        break;
    }
    if (apiData) {
      apiManager(apiData.mode, apiData.url, apiData.data)
        .then((res) => {
          if (res?.status) {
            swal("Success", res?.message, "success");
            getData();
            closeModal();
          } else {
            swal("Error", res?.message, "error");
          }
          setModalLoading(false);
        })
        .catch((err) => {
          console.log(err);
          swal("Error", err?.message, "error");
          setModalLoading(false);
        });
    } else {
      setModalLoading(false);
    }
  };

  return (
    <div class="overview">
      <div className="fs-3 mb-4 fw-bold">{"Specifications"}</div>
      <div class="set_list2 mb-0">
        <h3 class="" style={{ height: "unset" }}>
          <div className="row">
            <div className="col-lg-6">{"Features"}</div>
            <div className="col-lg-6 col-sm-12 d-sm-flex d-block ">
              <div
                className="col-lg-6 d-flex justify-content-center align-items-center"
                style={{ marginLeft: "0px", textDecoration: "none" }}
              >
                <Input
                  className="mx-sm-3 mx-0 my-3 my-sm-0 w-100"
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                  placeholder="Search"
                />
              </div>
              <div
                className="col-lg-6 btn btn-primary d-flex justify-content-center align-items-center"
                style={{
                  marginLeft: "0px",
                  textDecoration: "none",
                  backgroundColor: "#4c7ee8",
                }}
                onClick={() => openModal({ type: "add", title: "Add Feature" })}
              >
                Add Feature
              </div>
            </div>
          </div>
        </h3>
        <BasicTable
          columns={featuresTableColoumns}
          rows={data || []}
          actions={actions}
          submitting={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemperpage={limit}
        />

        <BoostrapModel
          show={modal}
          heading={modalData?.title}
          component={
            <PreferencesModal
              modalFor={"feature"}
              onClose={closeModal}
              data={modalData}
              submitCallback={handleSubmitFeatures}
              loading={modalLoading}
            />
          }
          onHide={closeModal}
        />
      </div>
    </div>
  );
};
export default Features;
