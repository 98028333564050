import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SendNotificationOption from "./sendNotificationOption";
import UsersOption from "./usersOption";
import { sendNotificationUser } from "../../../Reducer/sendNotification/sendNotificationSlice";
import ChecboxWithSpan from "../../Tools/CheckboxWithSpan";
import Button from "../../Tools/Button";
import SearchBar from "../../Tools/SearchBar";
import { all } from "axios";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useDidMountEffectWithDebounce } from "../../../data/hooks";
const NotificationFilter = ({
  allFilter,
  setAllFilter,
  getName,
  selectUnselectUser,
}) => {
  const [active, setActive] = React.useState("dealer");
  const users = useSelector((state) => state.sendNotificationusers?.users);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(sendNotificationUser({ userType: active }));
  }, [dispatch, active]);

  // const handleSearch = (data) => {
  //   dispatch(sendNotificationUser({ userType: active, name: data }));
  // };

  // useEffect(() => {
  //   dispatch(sendNotificationUser({ userType: active, name: searchTerm }));
  //   // console.log(searchTerm);
  // }, [searchTerm]);
  
  useDidMountEffectWithDebounce(() => {
    dispatch(sendNotificationUser({ userType: active, name: searchTerm }));

  }, [searchTerm], 500)

  console.log(allFilter);

  return (
    <div class="sideb">
      <div class="set">
        <h6>Send to</h6>
        <SendNotificationOption
          allFilter={allFilter}
          setAllFilter={setAllFilter}
        />
        <UsersOption allFilter={allFilter} setAllFilter={setAllFilter} />
      </div>
      <div class="set">
        <h5>Specific users</h5>
        <div class="switch-button " style={{ width: "unset" }}>
          <Button
            style={`switch-button-case ${active == "dealer" && "activeButton"}`}
            title={"Dealers"}
            onClick={() => setActive("dealer")}
          />
          <Button
            style={`switch-button-case ${active == "user" && "activeButton"}`}
            title={"Private users"}
            onClick={() => setActive("user")}
          />
        </div>
        <SearchBar
          placeholder={"Search Users"}
          // event={{ onclick: handleSearch }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <ul
          className="thinScroll"
          style={{ height: "300px", overflowY: "scroll" }}
        >
          {users &&
            users?.map?.((item, index) => {
              return (
                <li
                  style={{ padding: "0px 15px 0px" }}
                  className="d-flex align-items-center"
                >
                  <ChecboxWithSpan
                    id={item?.id}
                    // parentStyle={"checkcontainer"}
                    childStyle={"radiobtn"}
                    inType={"checkbox"}
                    inputStyle={"quality"}
                    // disabled={allFilter.sendTo.length > 0}
                    name={`user[${index}]`}
                    value={item?._id}
                    title={`${getName(item)}`}
                    checked={allFilter.users.includes(item?._id)}
                    onChange={(e) =>
                      selectUnselectUser(e.target.checked, e.target.value, item)
                    }
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default NotificationFilter;
