import { useEffect, useState } from "react";
import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading";
import BasicTable from "../../Tools/Table";
import { ITEM_PER_PAGE, MAIN_WEB_DOMAIN } from "../../../data/varible";
import {
  getStats,
  getUserAdverts,
  sendEmail,
  toggleAdDisplay,
} from "../../../services/api";
import moment from "moment";
import BoostrapModel from "../../Tools/BoostrapModel";
import ReasonModel from "../../Tools/ReasonModel";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const AdvertList = ({ rows, columns, rowInformation, action, userId }) => {
  // hooks
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [status, setStatus] = useState("");
  const limit = ITEM_PER_PAGE;
  const [rowInfo, setRowInfo] = useState([]);
  const [actions, setActions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    id: "",
    label: "",
    heading: "",
    buttonTitle: "",
    option: [],
    type: "",
    modalType: "",
    notes: [],
  });

  const getStatus = (item) => {
    let name = "";
    if (!item?.isAdCompleted) {
      name = "Payment pending";
    } else if (item?.uploads?.length == 0) {
      name = ("Media not uploaded");
    } else if (!item?.isApproved) {
      if (item?.approveStatus === "pending") {
        name = ("Ad pending approval");
      } else if (item?.approveStatus === "rejected") {
        name = ("Ad rejected");
      }
    } else if (!item?.display) {
      if (item?.statusName?.en === "Sold") {
        name = ("Sold");
      } else {
        name = ("Ad inactive");
      }
    } else {
      name = item?.statusName?.["en"];
    }
    return name;
  };

  const getDisplay = (item) => {
    let name = "";
    if (!item?.isAdCompleted) {
      name = "";
    } else if (item?.uploads?.length == 0) {
      name = "";
    } else if (!item?.isApproved) {
      name = "";
    } else if (!item?.display) {
      if (item?.statusName?.en === "Sold") {
        name = "Sold";
      } else {
        name = "";
      }
    } else {
      name = item?.statusName?.en;
    }

    switch (name) {
      case "Sold":
        return false;
      case "Active":
        return true;
      default:
        return false;
    }
  }

  const getData = () => {
    setSubmitting(true);
    getUserAdverts({
      id: userId,
      page: currentPage,
      limit: limit,
      status: status,
    }).then((res) => {
      const formattedData = res?.data?.ads?.map((v) => ({
        item: `${v?.properties?.basicDetails?.make?.en || ""} ${
          v?.properties?.basicDetails?.model?.en || ""
        } ${v?.properties?.basicDetails?.makeYear || ""}`,
        date: moment.unix(v?.adCreatedAt).format("DD/MM/YYYY"),
        imppression: v?.totalViews,
        status: getStatus(v),
        _id: v?._id,
        rawData: {...v, display: getDisplay(v)},

      }));
      setData(formattedData);
      setTotalPages(res?.data?.totalPages);
      setSubmitting(false);
    });
    getStats({ userId: userId, type: "ad" }).then((res) => {
      if (res?.status) {
        const rowInfoData = [
          {
            count: res?.data?.totalAds == 0 ? "0" : res?.data?.totalAds,
            content: "Total Ads",
          },
          {
            count: res?.data?.liveAds == 0 ? "0" : res?.data?.liveAds,
            content: "live Ads",
          },
          {
            count: res?.data?.soldAds == 0 ? "0" : res?.data?.soldAds,
            content: "Sold",
          },
          {
            count: res?.data?.inactiveAds == 0 ? "0" : res?.data?.inactiveAds,
            content: "Inactive Ads",
          },
        ];
        setRowInfo(rowInfoData);
      }
      setSubmitting(false);
    });
  };
  useEffect(() => {
    getData();
    fetchActionsAdvert();
  }, [currentPage, status]);
  const options = [
    { name: "All", value: "" },
    { name: "Active", value: "active" },
    { name: "Sold", value: "sold" },
  ];

  const fetchActionsAdvert = async () => {
    const viewNoraml = {
      label: "View",
      image: "",
      onclick: (id, data) => {
        navigate(`/view_advert?id=${id}`);
      },
    };
    const edit = {
      label: "Edit Ad",
      image: "",
      onclick: (id, data) => {
        navigate(`/edit_advert?id=${id}`);
      },
    };
    const deleted = {
      label: "List/Delist Ad",
      image: "",
      onclick: (id, data) => {
        setState({
          id: id,
          title: data?.rawData?.display
            ? "delisting advert"
            : "relisting advert",
          heading: data?.rawData?.display ? "Delist advert" : "List advert",
          buttonTitle: "Submit",
          option: [],
          type: "note",
          modalType: "toggleAd",
          adData: data,
        });
        setShow(true);
      },
    };
    setActions([viewNoraml, edit, deleted]);
  };

  const handleSubmit = (data) => {
    let formData = {};
    setLoading(true);
    switch (state?.modalType) {
      case "toggleAd":
        const toggleAdData = {
          userId: userId,
          display: state?.adData?.rawData?.display ? "OFF" : "ON",
          isAdImageUploaded: state?.adData?.rawData?.display ? "" : "YES",
        };
        toggleAdDisplay(toggleAdData, state?.adData?._id).then((res) => {
          if (res.status) {
            formData = {
              subject: data?.title,
              message: data?.body,
              userIds: [userId],
              type: "0",
              attachments: selectedFile.map((v) => v?.s3URL),
            };
            sendEmail(formData).then((res) => {
              swal("Success", res?.message, "success");
              getData();
              setShow(false);
            });
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
    }
  };

  return (
    <div class="set_list2">
      <h3>
        {"Adverts"}
        <ul>
          <li>
            <select
              class="form-control"
              onChange={(e) => setStatus(e.target.value)}
            >
              {options.map((v, i) => {
                return (
                  <option key={i} value={v?.value}>
                    {v?.name}
                  </option>
                );
              })}
            </select>
          </li>
          <li>
            <HeadingWithDatePicker image={"../assets/images/calendar.png"} />
          </li>
        </ul>
      </h3>
      <BasicTable
        columns={columns}
        rows={data || []}
        rowInformation={rowInfo}
        actions={actions}
        actionType={"dropdown"}
        dropDownImage={"../assets/images/dash_img/threedot.png"}
        itemperpage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        submitting={submitting}
      />
      <BoostrapModel
        show={show}
        heading={state.heading}
        onHide={() => setShow(!show)}
        style={"w-100"}
        component={
          <ReasonModel
            id={state.id}
            label={state.title}
            buttonTitle={state.buttonTitle}
            option={state.option}
            type={state.type}
            data={state}
            setData={setState}
            handleSubmitEvent={handleSubmit}
            onHide={() => setShow(!show)}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            loading={loading}
          />
        }
      />
    </div>
  );
};
export default AdvertList;
