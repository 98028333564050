import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { aprrovalAdvertColumn } from "../../../data/data";
import BasicTable from "../../Tools/Table";
import {
  getAdTypes,
  sendEmail,
  toggleAdDisplay,
} from "../../../services/api";
import SingleBoxItem from "../../Tools/SingleBoxItem";
import { ITEM_PER_PAGE } from "../../../data/varible";
import { apiManager } from "../../../data/apiHandler";
import BoostrapModel from "../../Tools/BoostrapModel";
import swal from "sweetalert";
import ReasonModel from "../../Tools/ReasonModel";

const Adverts = () => {

  //states
  const [submitting, setSubmitting] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [row, setRow] = useState([]);
  const [actions, setActions] = React.useState([]);
  const [userType, setUserType] = useState(
    localStorage.getItem("adUserTypeTab") || "All"
  );
  const [adType, setAdType] = useState(
    localStorage.getItem("adAdTypeTab") || "sell"
  );
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    id: "",
    label: "",
    heading: "",
    buttonTitle: "",
    option: [],
    type: "",
    modalType: "",
    notes: [],
  });

  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState("");
  //toogle modal states
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const openModal = (data) => {
    setModalData(data);
    setModal(true);
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const limit = ITEM_PER_PAGE;
  const apiGetCalls = async () => {
    setSubmitting(true);
    const uType =
      userType === "All" ? "" : userType === "Private" ? "user" : "dealer";

    let adTypeId = "";
    const adData = await getAdTypes();

    if (adType == "sell") {
      adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
    }
    if (adType == "rent") {
      adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
    }

    apiManager(
      "GET",
      `opp/filteredAds?lang=en&limit=${limit}&page=${currentPage}&userType=${uType}&adType=${adTypeId}&display=ON`
    )
      .then((res) => {
        if (res.status) {
          let data = res?.data?.ads.map((item) => ({
            ...item,
            createdAt: moment.unix(item.adCreatedAt).format("DD/MM/YYYY"),
            item: `${item?.properties?.basicDetails?.make?.en} ${item?.properties?.basicDetails?.model?.en} ${item?.properties?.basicDetails?.makeYear}`,
            name:
              item?.owner?.dealerShipName &&
              item?.owner?.dealerShipName !== "N/A"
                ? item?.owner?.dealerShipName
                : item?.owner?.fullName && item?.owner?.fullName !== "N/A"
                ? item?.owner?.fullName
                : "--",
            email: item?.owner?.email,
            phoneNumber: item?.owner?.phoneNumber,
            address: item?.owner?.address,
            userType: item?.owner?.userType === "user" ? "Private" : "Dealer",
            adType: item?.adTypeName?.en,
            rawData: item
          }));
          setRow(data);
          setTotalCount(res?.data?.totalAds);
          setTotalPages(res?.data?.totalPages);
        } else {
          console.log(res);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
      fetchActionsAdvert();
  };

  useEffect(() => {
    apiGetCalls();
  }, [dispatch, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    apiGetCalls();
  }, [userType, adType]);


  const fetchActionsAdvert = async () => {
    const viewNoraml = {
      label: "View",
      image: "",
      onclick: (id, data) => {
        // navigate(`/view_advert?id=${id}`);
        navigate(`${"/view_advert"}?id=${id}`)
      },
    };
    const edit = {
      label: "Edit Ad",
      image: "",
      onclick: (id, data) => {
        navigate(`/edit_advert?id=${id}`);
      },
    };
    const deleted = {
      label: "List/Delist Ad",
      image: "",
      onclick: (id, data) => {
        setState({
          id: id,
          title: data?.rawData?.display
            ? "delisting advert"
            : "relisting advert",
          heading: data?.rawData?.display ? "Delist advert" : "List advert",
          buttonTitle: "Submit",
          option: [],
          type: "note",
          modalType: "toggleAd",
          adData: data,
        });
        setShow(true);
      },
    };
    setActions([viewNoraml, edit, deleted]);
  };


  const handleSubmit = (data) => {
    let formData = {};
    setLoading(true);
    switch (state?.modalType) {
      case "toggleAd":
        const toggleAdData = {
          userId: state?.adData?.owner?._id,
          display: state?.adData?.display ? "OFF" : "ON",
          isAdImageUploaded: state?.adData?.display ? "" : "YES",
        };
        toggleAdDisplay(toggleAdData, state?.adData?._id).then((res) => {
          if (res.status) {
            formData = {
              subject: data?.title,
              message: data?.body,
              userIds: [state?.adData?.owner?._id],
              type: "0",
              attachments: selectedFile.map((v) => v?.s3URL),
            };
            sendEmail(formData).then((res) => {
              swal("Success", res?.message, "success");
              apiGetCalls();
              setShow(false);
            });
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
    }
  };

  return (
    <div class="overview">
      <div className="fs-3 mb-4 fw-bold">{userType} adverts</div>
      <div className="row">
        <div className="mb-2 col-xl-6">
          <div
            class="btn-group border my-tabs w-75"
            style={{ borderRadius: "4px", height: "43px" }}
            role="group"
            aria-label="Basic example"
          >
            <button
              disabled={submitting}
              type="button"
              class={`btn btn-light ${userType == "All" ? "activeButton" : ""}`}
              style={{ width: "150px" }}
              onClick={() => {
                setUserType("All");
                localStorage.setItem("adUserTypeTab", "All");
                // setData(tradeUsers);
              }}
            >
              All
            </button>
            <button
              disabled={submitting}
              type="button"
              class={`text-nowrap btn btn-light ${
                userType == "Dealer" ? "activeButton" : ""
              }`}
              style={{ width: "150px" }}
              onClick={() => {
                setUserType("Dealer");
                localStorage.setItem("adUserTypeTab", "Dealer");
                // setData(indiviualUsers);
              }}
            >
              Dealer Ads
            </button>
            <button
              disabled={submitting}
              type="button"
              class={`text-nowrap btn btn-light ${
                userType == "Private" ? "activeButton" : ""
              }`}
              style={{ width: "150px" }}
              onClick={() => {
                setUserType("Private");
                localStorage.setItem("adUserTypeTab", "Private");
                // setData(indiviualUsers);
              }}
            >
              Private Ads
            </button>
          </div>
        </div>

        <div className="mb-2 col-xl-6 d-flex justify-content-end">
          <div
            class="btn-group border my-tabs w-75"
            style={{ borderRadius: "4px", height: "43px" }}
            role="group"
            aria-label="Basic example"
          >
            <button
              disabled={submitting}
              type="button"
              class={`btn btn-light ${adType == "sell" ? "activeButton" : ""}`}
              style={{ width: "150px" }}
              onClick={() => {
                setAdType("sell");
                localStorage.setItem("adAdTypeTab", "sell");
                // setData(tradeUsers);
              }}
            >
              Sell
            </button>
            <button
              disabled={submitting}
              type="button"
              class={`btn btn-light ${adType == "rent" ? "activeButton" : ""}`}
              style={{ width: "150px" }}
              onClick={() => {
                setAdType("rent");
                localStorage.setItem("adAdTypeTab", "rent");
                // setData(indiviualUsers);
              }}
            >
              Rent
            </button>
          </div>
        </div>
      </div>
      <BasicTable
        columns={aprrovalAdvertColumn ? aprrovalAdvertColumn : []}
        rows={row ? row : []}
        actions={actions}
        actionType={"dropdown"}
        dropDownImage={"../assets/images/dash_img/threedot.png"}
        // view={view}
        itemperpage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        submitting={submitting}
        onRowClick={(e) =>
          navigate(`${"/view_advert"}?id=${e?._id}`)
        }
        totalPages={totalPages}
      />
      <SingleBoxItem
        parentStyle={"indiv"}
        childStyle={"st"}
        heading={totalCount}
        description={"Total Resuls ( only active ads )"}
      />
      <BoostrapModel
        show={show}
        heading={state.heading}
        onHide={() => setShow(!show)}
        style={"w-100"}
        component={
          <ReasonModel
            id={state.id}
            label={state.title}
            buttonTitle={state.buttonTitle}
            option={state.option}
            type={state.type}
            data={state}
            setData={setState}
            handleSubmitEvent={handleSubmit}
            onHide={() => setShow(!show)}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            loading={loading}
          />
        }
      />
    </div>
  );
};
export default Adverts;